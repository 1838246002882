import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_staff = `${BASE_URL}:gfz8um1p`;
const StaffDetails = `${BASE_URL}:gfz8um1p/staff/:id`;
const staff_auth = `${BASE_URL}:uw_L93nW`;
const reassign_staff = `${BASE_URL}:gfz8um1p/reassign_staff`;
const get_all_staff =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:gfz8um1p/getallstaff";
const patch_notification =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:nmGV7hFP/notificationPreferance";
const get_staff_notfications =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:gfz8um1p/getStaff";

// Get Staff Details
export type GetStaffDetailsRequestType = Partial<{
  staff_id: string | number;
  headers: { Authorization: string };
}>;

export type GetStaffDetailsResponseType = Partial<{}>;

export const getStaffDetails = (
  payload: AxiosRequestConfig<GetStaffDetailsRequestType>,
): AxiosPromise<GetStaffDetailsResponseType> => {
  const staffId = payload.data?.staff_id;

  if (staffId === undefined) {
    throw new Error("staff_id is not provided in the payload.");
  }

  const url = StaffDetails.replace(":id", staffId.toString());
  return apis.get(url, payload);
};

// Get Staff Details

export type UpdateStaffRequestType = Partial<{
  headers: { Authorization: string };
  staff_id: string | number;
  fname: string;
  email: string;
  phone_number: string;
  role: string;
  groupID: string | number;
  branch: string | number;
  employeeID: string | number;
}>;
export type UpdateStaffResponseType = Partial<{}>;

export const updateStaff = (
  payload: AxiosRequestConfig<UpdateStaffRequestType>,
): AxiosPromise<UpdateStaffResponseType> => {
  const url = staff_staff + "/staff/" + payload.data?.staff_id;
  return apis.put(url, payload);
};

// post send forgot password email
export type PostSendForgotPasswordEmailRequestType = Partial<{
  email: string;
}>;
export type PostSendForgotPasswordEmailResponseType = Partial<{}>;

export const postSendForgotPasswordEmail = (
  payload: AxiosRequestConfig<PostSendForgotPasswordEmailRequestType>,
): AxiosPromise<PostSendForgotPasswordEmailResponseType> => {
  const url = staff_auth + "/staff/forget-mail";
  return apis.post(url, payload);
};

// delete staff
export type DeleteStaffRequestType = Partial<{
  headers: { Authorization: string };
  staff_id: string | number;
}>;
export type DeleteStaffResponseType = Partial<{}>;

export const deleteStaff = (
  payload: AxiosRequestConfig<DeleteStaffRequestType>,
): AxiosPromise<DeleteStaffResponseType> => {
  const url = staff_staff + "/staff";
  return apis.delete(url, payload);
};

// activate staff
export type ActivateStaffRequestType = Partial<{
  headers: { Authorization: string };
  staff_id: string | number;
}>;
export type ActivateStaffResponseType = Partial<{}>;

export const activateStaff = (
  payload: AxiosRequestConfig<ActivateStaffRequestType>,
): AxiosPromise<ActivateStaffResponseType> => {
  const url = staff_staff + "/activate/" + payload.data?.staff_id;
  return apis.put(url, payload);
};

// Reassign Staff
export type ReassignStaffRequestType = Partial<{
  prev_relationship_manager: string | number;
  new_relationship_manager: string | number;
  partner_list: (string | number)[];
  headers: { Authorization: string };
}>;

export type ReassignStaffResponseType = Partial<{}>;

export const reassignStaff = (
  payload: AxiosRequestConfig<ReassignStaffRequestType>,
): AxiosPromise<ReassignStaffResponseType> => {
  return apis.put(reassign_staff, payload);
};

// Get All Staff

export type GetAllStaffRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetAllStaffResponseType = Partial<{}>;

export const getAllStaff = (
  payload: AxiosRequestConfig<GetAllStaffRequestType>,
): AxiosPromise<GetAllStaffResponseType> => {
  return apis.get(get_all_staff, payload);
};

export type PatchEditNotificationPreferenceRequest = Partial<{
  headers: {
    Authorization: string;
  };
  staff_id: string;
  email: boolean;
  whatsapp: boolean;
}>;

export type PatchEditNotificationPreferenceResponse = Partial<{}>;

export const PatchEditPreferenceNotification = (
  payload: AxiosRequestConfig<PatchEditNotificationPreferenceRequest>,
): AxiosPromise => apis.patch(patch_notification, payload);

export type GetStaffNotificationRequestType = Partial<{
  headers: { Authorization: string };
  staff_id: string;
}>;

export type GetStaffNotificationResponseType = Partial<{
  id: string;
  channel_id: string;
  notif_preferance: {
    email: boolean;
    whatsapp: boolean;
  };
}>;

export const getStaffNotification = (
  payload: AxiosRequestConfig<GetStaffNotificationRequestType>,
): AxiosPromise<GetStaffNotificationResponseType> => {
  return apis.get(get_staff_notfications, payload);
};
