import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { LeadType, OpportunityType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { channel_options, close_reason_options } from "common/filters";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Img, Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import CollectDoc from "pages/CustomerApp/collectdoc";
import { RowDetailsSheet } from "pages/Opportunities/opportunitydetails";
import {
  channel_options_inbound,
  channel_options_outbound,
  getRelativeDate,
} from "pages/Opportunities/utils/featureRule";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLeadReqType, GetLeads } from "service/apis/Opportunities";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { Option } from "components/Form/form";
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { useDebounce } from "pages/CreateDeal/newdeal";

interface LeadGenericProps {
  type: string;
}
export interface ChannelOptions {
  [key: string]: { value: string; label: string; icon: string; type: string }[];
}
const columns: ColumnDef<LeadType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="ID"
      />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: "includesString",
  },
  {
    id: "contactNumber",
    accessorKey: "contactNumber",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="hidden p-0 -mx-4"
        column={column}
        title="Contact Number"
      />
    ),
    cell: ({ row }) => (
      <div className="hidden p-0 -mx-4">{row.getValue("contactNumber")}</div>
    ),
  },
  {
    id: "customer",
    accessorKey: "customer",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Customer"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className=" flex gap-1.5 w-[150px] flex-col">
          <p className="capitalize">{row.getValue("customer")}</p>
          <p className="font-normal text-sm text-[#666666]">
            {row.original?.contactNumber}
          </p>
        </div>
      );
    },
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "product",
    accessorKey: "product",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Product"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          <p className="capitalize">{row.getValue("product")}</p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row?.original?.productSubCategory}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "assignedTo",
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Assigned To"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          <p className="capitalize">
            {row.getValue("assignedTo") || localStorage.getItem("owner_name")}
          </p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row?.original?.assignedToCity}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "channel",
    accessorKey: "channel",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Channel"
      />
    ),
    cell: ({ row }) => {
      const newSource = (channel_options as ChannelOptions)?.[
        (row?.original?.channelType as string)?.toLowerCase()
      ]?.find(
        (item) =>
          item.value?.toLowerCase() ===
          (row.getValue("channel") as string)
            ?.toLowerCase()
            .split(" ")
            .join(""),
      );

      return newSource?.value ? (
        <div className="grid grid-cols-1 max-w-[120px] place-content-center gap-1">
          <div className="flex items-start flex-col gap-3 justify-between w-full">
            <div className="h-full w-full flex gap-10">
              <p className="capitalize w-full">{newSource?.label}</p>
              <Img
                className="text-right h-5 w-5"
                src={newSource?.icon}
                alt="icon"
              />
            </div>
            <p className="capitalize font-normal text-sm text-[#666666]">
              {["outletID", "partnerOffice", "contact"].includes(
                row?.original?.channelSource as string,
              )
                ? row?.original?.channelSource === "partnerOffice"
                  ? "Partner"
                  : row?.original?.channelSource === "contact"
                    ? "Contact"
                    : row?.original?.channel === "Walk-in"
                      ? ""
                      : "Employee"
                : row?.original?.channelSource === "linkdin"
                  ? "LinkedIn"
                  : row?.original?.channelSource}
            </p>
          </div>
        </div>
      ) : (
        <p className="capitalize">{row?.original?.channelType}</p>
      );
    },
    enableSorting: false,
  },
  {
    id: "createDate",
    accessorKey: "createDate",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Create Date"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex w-full flex-col gap-1.5">
          <p className="w-full whitespace-nowrap">{row.original.createdAt}</p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row.original.createdBy}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "leadStatus",
    accessorKey: "leadStatus",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="whitespace-nowrap"
        title="Lead Status"
      />
    ),
    cell: ({ row }) => {
      const values = close_reason_options.find(
        (item) => item.value === (row.getValue("leadStatus") as string),
      );
      if (!values) {
        return (
          <p
            style={{ color: "#23AB28", backgroundColor: "#E1FFE2" }}
            className="py-[3px] px-2 text-center rounded-md"
          >
            Open
          </p>
        );
      }
      return (
        <p
          style={{ color: values?.textColor, backgroundColor: values?.bgColor }}
          className="py-[3px] px-2 text-center rounded-md"
        >
          {values?.label}
        </p>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: false,
  },
];

type Checked = DropdownMenuCheckboxItemProps["checked"];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "19", label: "Family Maintenance" },
  { value: "20", label: "Gift" },
];
const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

const productPurposeOptions = {
  remittance: remitProductPurposeOptions,
  visa: visaProductPurposeOptions,
  travel: travelProductPurposeOptions,
  forex: forexProductPurposeOptions,
};

const channelOptions = {
  "I Contacted": channel_options_inbound
    .map((option) => ({ label: option.label, value: option.value.toString() }))
    .filter((option) => option.label !== "Meeting"),
  "Customer Contacted": channel_options_outbound.map((option) => {
    if (option.label === "Refferal") {
      return { label: "Referral", value: option.value.toString() };
    }
    return { label: option.label, value: option.value.toString() };
  }),
};

export default function LeadGeneric({ type }: LeadGenericProps) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<OpportunityType>();
  const [openCreateLead, setOpenCreateLead] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [showStatusBar, setShowStatusBar] = useState<Checked>(false);
  const [showActivityBar, setShowActivityBar] = useState<Checked>(false);
  const [showPanel, setShowPanel] = useState<Checked>(false);
  const [activeFilter, setActiveFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>([]);
  const [channelFilter, setChannelFilter] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  //   const filteredColumns = useMemo(() => {
  //     return columns.filter((column) => {
  //       // Add conditions to hide specific columns based on pipelineType
  //         return !["contactNumber"].includes(column?.id as string);
  //     });
  //   }, [loading]);

  const fetchLeads = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetLeadReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: "retail",
        productType: type,
        searchQuery: search,
        status: statusFilter,
        product_id: productFilter,
        channel_of_interaction_id: channelFilter,
      },
    };

    const name = localStorage.getItem("name")?.toString();

    try {
      const resposne = (await GetLeads(payload)) as any;
      setTableData(
        resposne?.data?.map((item: any) => {
          return {
            id: item?.id,
            customer: item?._contacts?.[0]?.contactName
              ? item?._contacts?.[0]?.contactName
              : "N/A",
            product: item?._product?.name,
            productSubCategory: item?._product?.subCategory,
            channel: item?._channel_of_interaction?.[0]?.Channel,
            createdBy: item?.createdBy !== 0 ? item?._createdBy?.fname : name,
            assignedTo: item?._assignedTo?.fname,
            assignedToCity: item?._assignedTo?.city,
            leadStatus: item?.reasonToClose,
            createdAt: getRelativeDate(item?.created_at),
            contactNumber: item?._contacts?.[0]?.contactNumber
              ? item?._contacts?.[0]?.contactNumber
              : "N/A",
            channelType: item?._channel_of_interaction?.[0]?.Type,
            channelSource: item?._channel_of_interaction?.[0]?.source,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const newProductPurposeOptions =
    productPurposeOptions[
      type.toLowerCase() as keyof typeof productPurposeOptions
    ];
  const filterOptions: Record<
    string,
    { label: string; options: { label: string; value: string }[] }
  > = {
    status: {
      label: "By Status",
      options: [
        { label: "Open", value: "true" },
        // { label: "Closed", value: "close" },
        { label: "Buy Later", value: "buyLater" },
        { label: "Not Interested", value: "notInterested" },
        { label: "Not Serviceable", value: "notServicable" },
        { label: "Spam", value: "spam" },
        { label: "No Response", value: "noResponse" },
        { label: "Success", value: "success" },
      ],
    },
    product: {
      label: "By Product",
      options: newProductPurposeOptions,
    },
    channel: {
      label: "By Channel",
      options: Object.values(channelOptions).flat(),
    },
  };
  const onRowClick = (row: any) => {
    setIsRowDetailsOpen(true);
    setSelectedRowData({
      uniqueId: row.id,
      id: row.id,
      name: row.customer,
      entityName: row.id,
      companyId: row.id,
      contactId: row.id,
      companyName: row.id,
      product: row.product,
      assignedTo: row.assignedTo,
      createDate: row.createdAt,
      opportunityStatus: row.id,
    });
  };

  useDebounce(
    () => {
      fetchLeads();
    },
    500,
    [search],
  );

  // clear filters on path changes
  useEffect(() => {
    setStatusFilter([]);
    setProductFilter([]);
    setChannelFilter([]);
    setActiveFilter([]);
    setShowStatusBar(false);
    setShowActivityBar(false);
    setShowPanel(false);
    setSearch("");
  }, [pathname]);

  useEffect(() => {
    fetchLeads();
  }, [type, statusFilter, productFilter, channelFilter]);
  return (
    <>
      {loading && <Spinner />}
      <CollectDoc
        dialogOpen={openCreateLead}
        handleDialogOpen={setOpenCreateLead}
      />
      <div
        // style={{
        //   margin: pathname === "/remit/leads" ? "0" : "-1rem",
        //   padding: pathname === "/remit/leads" ? "0" : "1rem",
        // }}
        className="flex h-full gap-10 py-6 px-3 sm:px-0 flex-col overflow-hidden"
      >
        {pathname !== "/remit/leads" && (
          <div className="flex w-full justify-between px-2 items-center">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize text-base">Leads</span>
            </h3>
            {pathname === "/leads/forex" && (
              <button
                onClick={() => setOpenCreateLead(true)}
                className="bg-blue-700 text-white-A700 px-4 py-2.5 rounded-lg"
              >
                Collect Docs
              </button>
            )}
          </div>
        )}
        <div className="">
          <div className="w-full flex flex-col gap-8">
            {/* <div className="flex justify-between w-full">
              <Tabs
                className="bg-navbar-menu-bg w-fit flex rounded-lg gap-6"
                value={activeTab}
                onValueChange={(val) => {
                  setTab(val);
                  // if (val === "all") {
                  //   fetchSearchResults();
                  // } else {
                  //   fetchCustomerContacts();
                  // }
                }}
              >
                <TabsList className="h-[44px]">
                  {tabData
                    .filter((tab) => true)
                    .map((tab, index: number) => (
                      <TabsTrigger
                        key={index}
                        value={tab.value}
                        disabled={!tab.enabled}
                        className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] max-w-[125px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                      >
                        {tab.label}
                      </TabsTrigger>
                    ))}
                </TabsList>
              </Tabs>
              <Link className="sm:hidden" to={"#"}>
                <button disabled className="rounded-lg disabled:opacity-40 py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                  + New Lead
                </button>
              </Link>
            </div> */}
            <div className="flex justify-between items-start w-full">
              {/* <Tabs
                className="flex rounded-lg gap-2"
                value={assignedType}
                onValueChange={(tab) => {
                  setAssignedType(tab);
                }}
              >
                <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                  {assignedTypeOptions.map(
                    (tab: { label: string; value: string }) => (
                      <TabsTrigger
                        key={tab.value}
                        value={tab.value}
                        className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === assignedType ? " bg-[#E1EBFC] text-blue-700" : " text-black-label bg-none"}`}
                      >
                        {tab.label}
                      </TabsTrigger>
                    ),
                  )}
                </TabsList>
              </Tabs> */}
              <div className="flex w-full sm:flex-col justify-end gap-5">
                <div className="flex gap-5 items-center">
                  {activeFilter.length > 0 &&
                    activeFilter.map((filter) => {
                      return (
                        <DropdownMenu>
                          <DropdownMenuTrigger className="z-50" asChild>
                            <button className="py-2 relative z-50 px-3.5 flex items-center gap-2 bg-[#F9F9F9] rounded-lg text-sm font-medium text-black-label">
                              {filter === "status" &&
                              statusFilter.length > 0 ? (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (filter === "status") {
                                      setStatusFilter([]);
                                      setShowStatusBar(false);
                                    }
                                    setActiveFilter(
                                      activeFilter.filter((f) => f !== filter),
                                    );
                                  }}
                                  className="absolute right-0 -top-4 pointer-events-auto"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  <svg
                                    className=""
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                      fill="#165FE3"
                                    />
                                  </svg>
                                </button>
                              ) : filter === "product" &&
                                productFilter.length > 0 ? (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (filter === "product") {
                                      setProductFilter([]);
                                      setShowActivityBar(false);
                                    }
                                    setActiveFilter(
                                      activeFilter.filter((f) => f !== filter),
                                    );
                                  }}
                                  className="absolute right-0 -top-4 pointer-events-auto"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                      fill="#165FE3"
                                    />
                                  </svg>
                                </button>
                              ) : filter === "channel" &&
                                channelFilter.length > 0 ? (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (filter === "channel") {
                                      setChannelFilter([]);
                                      setShowPanel(false);
                                    }
                                    setActiveFilter(
                                      activeFilter.filter((f) => f !== filter),
                                    );
                                  }}
                                  className="absolute right-0 -top-4 pointer-events-auto"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 16C3.58698 16 0 12.413 0 8C0 3.58698 3.58698 0 8 0C12.413 0 16 3.58698 16 8C16 12.413 12.413 16 8 16ZM8 1.11628C4.20465 1.11628 1.11628 4.20465 1.11628 8C1.11628 11.7953 4.20465 14.8837 8 14.8837C11.7953 14.8837 14.8837 11.7953 14.8837 8C14.8837 4.20465 11.7953 1.11628 8 1.11628Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M5.89222 10.6624C5.75082 10.6624 5.60943 10.6104 5.4978 10.4987C5.28198 10.2829 5.28198 9.92571 5.4978 9.70989L9.70989 5.4978C9.9257 5.28198 10.2829 5.28198 10.4987 5.4978C10.7145 5.71361 10.7145 6.07082 10.4987 6.28663L6.28663 10.4987C6.18245 10.6104 6.03361 10.6624 5.89222 10.6624Z"
                                      fill="#165FE3"
                                    />
                                    <path
                                      d="M10.1043 10.6624C9.96291 10.6624 9.82152 10.6104 9.70989 10.4987L5.4978 6.28663C5.28198 6.07082 5.28198 5.71361 5.4978 5.4978C5.71361 5.28198 6.07082 5.28198 6.28663 5.4978L10.4987 9.70989C10.7145 9.92571 10.7145 10.2829 10.4987 10.4987C10.3871 10.6104 10.2457 10.6624 10.1043 10.6624Z"
                                      fill="#165FE3"
                                    />
                                  </svg>
                                </button>
                              ) : null}
                              {filter === "status"
                                ? "By status"
                                : filter === "channel"
                                  ? "By channel"
                                  : "By product"}
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6181 4.19035L6.81156 7.93679C6.72851 8.01895 6.61639 8.06504 6.49956 8.06504C6.38273 8.06504 6.27062 8.01895 6.18756 7.93679L2.381 4.19116C2.29743 4.10904 2.18495 4.06302 2.06778 4.06302C1.95061 4.06302 1.83813 4.10904 1.75456 4.19116C1.71341 4.23128 1.6807 4.27923 1.65837 4.33219C1.63604 4.38514 1.62454 4.44203 1.62454 4.49951C1.62454 4.55698 1.63604 4.61387 1.65837 4.66682C1.6807 4.71978 1.71341 4.76773 1.75456 4.80785L5.56031 8.55348C5.81098 8.7996 6.14825 8.9375 6.49956 8.9375C6.85087 8.9375 7.18814 8.7996 7.43881 8.55348L11.2446 4.80785C11.2858 4.76772 11.3186 4.71972 11.3411 4.66669C11.3635 4.61366 11.375 4.55667 11.375 4.4991C11.375 4.44153 11.3635 4.38455 11.3411 4.33151C11.3186 4.27848 11.2858 4.23048 11.2446 4.19035C11.161 4.10823 11.0485 4.06221 10.9313 4.06221C10.8142 4.06221 10.7017 4.10823 10.6181 4.19035Z"
                                  fill="#333333"
                                />
                              </svg>
                            </button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onSelect={(e) => e.preventDefault()}
                            className="w-56"
                          >
                            {filter === "product" ? (
                              <Accordion
                                className="w-full"
                                type="single"
                                collapsible
                              >
                                <AccordionItem
                                  value={type}
                                  className="border-none -m-1"
                                >
                                  <AccordionTrigger className="capitalize hover:no-underline [&[data-state=open]]:bg-[#E1EBFC] py-3.5 px-5">
                                    {type}
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    {newProductPurposeOptions.map((option) => (
                                      <DropdownMenuCheckboxItem
                                        key={option.value}
                                        onSelect={(e) => e.preventDefault()}
                                        checked={
                                          productFilter.includes(
                                            option.value,
                                          ) || "indeterminate"
                                        }
                                        onCheckedChange={(val) => {
                                          setProductFilter(
                                            val
                                              ? [...productFilter, option.value]
                                              : productFilter.filter(
                                                  (value) =>
                                                    value !== option.value,
                                                ),
                                          );
                                        }}
                                        className="py-3.5"
                                      >
                                        {option.label}
                                      </DropdownMenuCheckboxItem>
                                    ))}
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            ) : filter === "channel" ? (
                              <Accordion
                                className="w-full"
                                type="single"
                                collapsible
                              >
                                {Object.keys(channelOptions).map((key) => {
                                  const typedKey =
                                    key as keyof typeof channelOptions;
                                  return (
                                    <AccordionItem
                                      key={key}
                                      className="border-none -m-1"
                                      value={key}
                                    >
                                      <AccordionTrigger className="capitalize hover:no-underline [&[data-state=open]]:bg-[#E1EBFC] py-3.5 px-5">
                                        {key}
                                      </AccordionTrigger>
                                      <AccordionContent>
                                        {channelOptions[typedKey].map(
                                          (option) => (
                                            <DropdownMenuCheckboxItem
                                              key={option.value}
                                              onSelect={(e) =>
                                                e.preventDefault()
                                              }
                                              checked={
                                                channelFilter.includes(
                                                  option.value,
                                                ) || "indeterminate"
                                              }
                                              onCheckedChange={(val) => {
                                                setChannelFilter(
                                                  val
                                                    ? [
                                                        ...channelFilter,
                                                        option.value,
                                                      ]
                                                    : channelFilter.filter(
                                                        (value) =>
                                                          value !==
                                                          option.value,
                                                      ),
                                                );
                                              }}
                                              className="py-3.5"
                                            >
                                              {option.label}
                                            </DropdownMenuCheckboxItem>
                                          ),
                                        )}
                                      </AccordionContent>
                                    </AccordionItem>
                                  );
                                })}
                              </Accordion>
                            ) : (
                              filterOptions[filter].options.map((option) => (
                                <DropdownMenuCheckboxItem
                                  key={option.value}
                                  onSelect={(e) => e.preventDefault()}
                                  checked={
                                    statusFilter.includes(option.value) ||
                                    "indeterminate"
                                  }
                                  onCheckedChange={(val) => {
                                    setStatusFilter(
                                      val
                                        ? [...statusFilter, option.value]
                                        : statusFilter.filter(
                                            (value) => value !== option.value,
                                          ),
                                    );
                                  }}
                                  className="py-3.5"
                                >
                                  {option.label}
                                </DropdownMenuCheckboxItem>
                              ))
                            )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      );
                    })}
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9384 23.1516C10.4584 23.1516 9.98844 23.0316 9.54844 22.7916C8.66844 22.3016 8.13844 21.4116 8.13844 20.4116V15.1116C8.13844 14.6116 7.80844 13.8616 7.49844 13.4816L3.75844 9.52156C3.12844 8.89156 2.64844 7.81156 2.64844 7.00156V4.70156C2.64844 3.10156 3.85844 1.85156 5.39844 1.85156H18.5984C20.1184 1.85156 21.3484 3.08156 21.3484 4.60156V6.80156C21.3484 7.85156 20.7184 9.04156 20.1284 9.63156L15.7984 13.4616C15.3784 13.8116 15.0484 14.5816 15.0484 15.2016V19.5016C15.0484 20.3916 14.4884 21.4216 13.7884 21.8416L12.4084 22.7316C11.9584 23.0116 11.4484 23.1516 10.9384 23.1516ZM5.39844 3.35156C4.69844 3.35156 4.14844 3.94156 4.14844 4.70156V7.00156C4.14844 7.37156 4.44844 8.09156 4.82844 8.47156L8.63844 12.4816C9.14844 13.1116 9.64844 14.1616 9.64844 15.1016V20.4016C9.64844 21.0516 10.0984 21.3716 10.2884 21.4716C10.7084 21.7016 11.2184 21.7016 11.6084 21.4616L12.9984 20.5716C13.2784 20.4016 13.5584 19.8616 13.5584 19.5016V15.2016C13.5584 14.1316 14.0784 12.9516 14.8284 12.3216L19.1084 8.53156C19.4484 8.19156 19.8584 7.38156 19.8584 6.79156V4.60156C19.8584 3.91156 19.2984 3.35156 18.6084 3.35156H5.39844Z"
                          fill="#165FE3"
                        />
                        <path
                          d="M6.00358 11.2473C5.86358 11.2473 5.73358 11.2073 5.60358 11.1373C5.25358 10.9173 5.14358 10.4473 5.36358 10.0973L10.2936 2.19733C10.5136 1.84733 10.9736 1.73733 11.3236 1.95733C11.6736 2.17733 11.7836 2.63733 11.5636 2.98733L6.63358 10.8873C6.49358 11.1173 6.25358 11.2473 6.00358 11.2473Z"
                          fill="#165FE3"
                        />
                      </svg>
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-56"
                    onSelect={(e) => e.preventDefault()}
                  >
                    <DropdownMenuCheckboxItem
                      checked={showStatusBar || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowStatusBar(val);
                        if (val) {
                          setActiveFilter([...activeFilter, "status"]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "status",
                            ),
                          );
                          setStatusFilter([]);
                        }
                      }}
                      className="py-3.5 "
                    >
                      By Status
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      checked={showActivityBar || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowActivityBar(val);
                        if (val) {
                          setActiveFilter([...activeFilter, "product"]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "product",
                            ),
                          );
                          setProductFilter([]);
                        }
                      }}
                      className="py-3.5 "
                    >
                      By Products
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      checked={showPanel || "indeterminate"}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={(val) => {
                        setShowPanel(val);
                        if (val) {
                          setActiveFilter([...activeFilter, "channel"]);
                        } else {
                          setActiveFilter(
                            activeFilter.filter(
                              (filter) => filter !== "channel",
                            ),
                          );
                          setChannelFilter([]);
                        }
                      }}
                      className="py-3.5 "
                    >
                      By Channel
                    </DropdownMenuCheckboxItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <div className="relative flex items-center w-[300px]">
                  <Input
                    placeholder={"Search by name or mobile number"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] w-full"
                  />
                  <Search className="absolute left-2 h-3.5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          <DataTable
            columns={columns}
            data={[...tableData]}
            isToolbarVisible={false}
            onRowClick={onRowClick}
            highlightRow={isRowDetailsOpen}
            isPaginationVisible={true}
            searchData={{
              placeholder: "Search by name or mobile number",
              columns: ["id", "customer", "contactNumber", "assignedTo"],
            }}
            isPageCountVisible={true}
            defaultSorting={[{ id: "id", desc: true }]}
          />
          <RowDetailsSheet
            isOpen={isRowDetailsOpen}
            onOpenChange={setIsRowDetailsOpen}
            rowData={selectedRowData as OpportunityType}
            getOpportunityList={fetchLeads}
          />
        </div>
      </div>
    </>
  );
}
